/**
 * 套餐相关的API
 * 2021-12-22 18:55:51
 * @author SnowRock
 */
import request from '@/utils/request'

/**
 * 获取超值套餐列表
 * 2021-12-22 18:57:30
 * @author SnowRock
 * @param params
 * @param params.order_id 升级套餐时的ID信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchPackageList(params) {
  return request.post('/fontend/goods/list', params, {})
}

/**
 * 获取微信支付二维码下单信息
 * 2021-12-22 20:51:18
 * @author SnowRock
 * @param params
 * @param params.goods_id 所选套餐id
 */
export function fetchOrderPayByWxPay(params) {
  return request.post('/fontend/order/wxPay', params, {})
}

/**
 * 获取阿里支付二维码下单信息
 * 2021-12-22 20:51:31
 * @author SnowRock
 * @param params
 * @param params.goods_id 所选套餐id
 */
export function fetchOrderPayByAliPay(params) {
  return request.post('/fontend/order/aliPay', params, {})
}

/**
 * 获取订单对公转账信息
 * 2021-12-23 10:16:58
 * @author SnowRock
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchOrderPayByPublicPay(params) {
  return request.post('/fontend/order/publicPay', params, {})
}

/**
 * 提交付款回执信息
 * 2021-12-23 10:10:16
 * @author SnowRock
 * @param params
 * @param params.order_no 订单号
 * @param params.receipt_path	 转账凭证
 * @param params.remark	 转账备注`
 */
export function submitPublicPayReceipt(params) {
  return request.post('/fontend/order/sbPublicOrder', params, {})
}

// 零售订单对公转账
export function submitRetailPublicPay(params) {
  return request.post('/fontend/payment/retail-public-pay', params, {})
}

/**
 * 订单支付状态查询
 * 2021-12-23 10:52:34
 * @author SnowRock
 */
export function fetchOrderPayStatusByPackage(params) {
  return request.post('/fontend/pay/lookPayStatus', params, {})
}

/**
 * 发起连续包月签约
 * goods_id
 */
 export function initMonthOrderSign(params) {
  return request.post('/fontend/pay/initSign', params, {})
}

/**
 * 查看签约状态
 * contract_no 签约返回的签约码
 */
 export function fetchMonthOrderStatus(params) {
  return request.post('/fontend/pay/checkCycleStatus', params, {})
}

/**
 * H5首页、会员页相关接口
 * 2021-3-31 09:46
 * @author suxueliang
 */
import request from '@/utils/request'

/**
 * 获取首页相关banner
 * @param {*} params
 * @returns
 * @param params.limit 条数
 */
export function getBannerlist(params){
    return request.post('/mob/bannerlist', params)
}

/**
 * 获取对应的歌单banner下的歌单列表
 * @param param.banner_id // bannerid
 * @param param.playlist_id 对应歌单id
 */
export function getPlaylist(params){
    return request({
        url: '/mob/playlist',
        method: 'POST',
        data: params
    })
    // return request.get('/mob/playlist', {
    //     params: params
    // })
}

/**
 * 获取微信支付下单信息
 * @param param.order_id 订单ID
 * @param param.goods_id 套餐ID
 * @param param.share_token 分享token
 */
export function getOrderPayByWxPayH5(params) {
    return request.post('/mob/order/wxPay', params, {})
}

/**
 * H5订单支付状态查询
 * @param param.order_no 订单id
 * @param param.share_token 分享token
 */
export function fetchOrderPayStatusByH5(params) {
    return request.post('/mob/pay/lookPayStatus', params, {})
}

/**
 * 发起微信H5支付请求
 * @param param.total_amount 费用
 * @param param.order_no 订单号
 * @param param.user_id  用户id
 */
export function sendWechatPay(params){
    return request.post('/mob/pay/sendWechatPay', params, {})
}

/**
 * 获取用户已购买套餐
 */
export function getEnabledGoods(params){
    return request.post('/mob/enabledGoods', params)
}

/**
 * 发起支付宝支付请求
 */
export function sendAlipay(params){
    return request.post('/mob/order/aliPay', params, {})
}


// 获取标签列表
export function getLabels(params){
  return request({
    url: '/mob/labels',
    method: 'POST',
    data: params
  })
}
// 获取视频详情
export function getVideoinfo(params){
  return request({
    url: '/mob/videoinfo',
    method: 'POST',
    data: params
  })
}

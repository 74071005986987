<template>
  <div class="container">
    <div class="header">
        <div class="package_cont">
          <!-- 套餐选择 -->
          <div class="package_choose">
            <ul>
              <li v-for="(ele,index) in packageTabs" :key="index"
              :class="{active: packageType == ele.value}"
              @click="packageType = ele.value,choosePackage(ele.value)">
                {{ ele.tabName}}
              </li>
            </ul>
          </div>
          <!-- 会员选择 -->
          <div class="package-list">
            <div
              v-for="(i, j) in packageList"
              :key="`packages-li-${packageType}-${j}`"
              class="packages-li"
              :class="{
                select: i.goods_id === selectPackage.goods_id
              }"
              @click="handleSelectPackage(i)"
            >
              <div class="package-title">
                {{ i.name }}
              </div>
              <div class="package-price"><span>￥</span>{{ Number(i.price) }}</div>
              <div class="package-info">
                <span v-if="i.down_count === 'n'">不限</span>
                <span v-else>{{ i.down_count }}次</span>
                <span>{{ i.down_num }}次/天</span>
              </div>
            </div>
          </div>
<!--          <div class="confirm-text">需使用电脑进行资源下载</div>-->
        </div>
    </div>
    <section class="section">
      <div class="title">
        <p><span>随片永久</span><span>持续更新</span></p>
        <p><span>正品授权</span><span><label>100%正品</label>保障</span></p>
      </div>
      <div class="product-use">
        <div class="product-list">
          <ul>
            <li>
              <img src="@/assets/image/mobile/icon1.png" alt="">
              <span>自媒体平台</span>
            </li>
            <li>
              <img src="@/assets/image/mobile/icon2.png" alt="">
              <span>直播/FM</span>
            </li>
            <li>
              <img src="@/assets/image/mobile/icon3.png" alt="">
              <span>产品内置用途</span>
            </li>
            <li>
              <img src="@/assets/image/mobile/icon4.png" alt="">
              <span>家庭视频</span>
            </li>
            <li>
              <img src="@/assets/image/mobile/icon5.png" alt="">
              <span>影视作品</span>
            </li>
            <li>
              <img src="@/assets/image/mobile/icon6.png" alt="">
              <span>AI语音下载</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 立即开通 -->
      <div id="lijikaitong" data-agl-cvt="7" class="vip-open" @click="openVip">
        {{ vipOpenText }}
      </div>
    </section>

    <!-- 支付方式选择 -->
    <div class="payType_dialog" ref="payType" v-show="showPay_dialog" @click="handleClosePaytype">
      <div class="payType_cont">
        <div class="payType_mask">

        </div>
        <div class="payType_detail" ref="guide_type">
          <p>选择支付方式</p>
          <div class="form">
            <ul>
              <li v-for="(item,index) in payTypes" :key="index"
              :class="{active: curIndex == index}"
              @click="curIndex = index, payType = item.value">
                <img :src="item.icon" alt="" class="pay_icon">
                <span>{{ item.label }}</span>
              </li>
            </ul>
            <div class="btn_pay" @click="handlePay">
              立即支付
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 引导弹窗 -->
    <div class="guide_dialog" v-show="showGuide" ref="guide_dialog" @click="handleCloseGuide">
      <div class="guide_cont">
        <img src="@/assets/image/mobile/jiantou.png" alt="" class="jiantou">
        <div class="guide_detail" ref="guide_cont">
          <h3>点击右上角选项按钮</h3>
          <div class="browser">
            <div class="browser_detail">
              <img src="@/assets/image/mobile/ios_bro.png" alt="">
            </div>
            <div class="browser_detail">
              <img src="@/assets/image/mobile/android_bro.png" alt="">
            </div>
          </div>
          <p>复制链接后在手机浏览器中打开</p>
          <div class="gudie_type">
            <div class="type">
              <img src="@/assets/image/mobile/guide_icon1.png" alt="">
            </div>
            <div class="type">
              <img src="@/assets/image/mobile/guide_icon2.png" alt="">
            </div>
            <div class="type">
              <img src="@/assets/image/mobile/guide_icon3.png" alt="">
              <div class="copy_link">
                <span>复制链接</span>
              </div>
            </div>
            <div class="type">
              <img src="@/assets/image/mobile/guide_icon4.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 购买会员提示 -->
    <div class="buy_confirm_dialog" v-show="showDialog">
      <div class="confirm_cont">
        <div class="confirm_detail">
          <p><span>您当前已拥有【{{ text }}】</span><br>可购买多个会员，增加下载次数</p>
          <div class="btn">
            <div class="cancel" @click="showDialog = false">
              取消
            </div>
            <div class="confirm" @click="confirm">
              确认购买
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchPackageList
} from '@/api/package'
import {
  getOrderPayByWxPayH5,
  fetchOrderPayStatusByH5,
  getEnabledGoods,
  sendAlipay
  // sendWechatPay
} from '@/api/mobile'
import { mapGetters } from 'vuex'
import { formDateTime } from '@/utils/formDate.js'
import { send_eventApi } from '@/utils/common.js'
export default {
    data(){
        return{
          showDialog: false, // 已购买套餐弹窗提示
          showSuccessDialog: false, // 开通会员成功弹窗
          showGuide: false, // 支付引导弹窗
          showPay_dialog: false, // 支付方式选择弹框
          order_id: null,
          packages: {},
          selectPackage: {},
          text: '', // 购买文案提示
          packageTabs: [
            {tabName: '个人(非商用)', value: 1},
            {tabName: '企业(商用)', value: 2}
          ],
          packageType: 2, // 套餐类型
          // 定时器
          wxPayTimer: null,
          vipType: '立即开通',
          share_token: '', // 分享id
          package_name: '', // 套餐名称
          total_amount: '', // 会员金额
          pay_url: '', // 调起微信支付url
          goods_id: '', // 套餐id
          enabledGoodsList: [], // 已购买会员套餐类型
          payTypes: [
            { value: 'weixin', label: '微信支付', icon: require('@/assets/image/mobile/weixin.png') },
            { value: 'Alipay', label: '支付宝支付', icon: require('@/assets/image/mobile/zfb.png') },
          ],
          curIndex: 0, // 默认支付方式
          payType: 'weixin' // 支付方式
        }
    },
    computed:{
      packageList() {
        return this.packages[this.packageType]
          ? this.packages[this.packageType].filter((i) => i.is_able === 1)
          : []
      },
      vipOpenText() {
        return '立即开通'
      },
      ...mapGetters(['userInfo'])
    },
    created(){
      if(!this.userInfo){
        this.$router.push({ path: '/login' })
      }else{
        this.getPackageList()
        this.getEnableGoodslist();
      }
      // 判断是否点击618活动进入
      if(this.GetQueryString('packageType')){
        this.packageType = 2;
      }
    },
    mounted(){
      this.share_token =  window.localStorage.getItem('shareMobile_token') ? window.localStorage.getItem('shareMobile_token') : '';
    },
    methods:{
      /**
       * 获取地址栏参数
       */
      GetQueryString(name){
          var reg= new RegExp("(^|&)"+name+"=([^&]*)(&|$)","i");
          var r = window.location.search.substr(1).match(reg);
          if(r!=null)
          return decodeURI(r[2]);
          return null
      },
      /**
       * 获取用户名下所有已购会员套餐类型
       */
      async getEnableGoodslist(){
        const obj = {
          user_id: this.userInfo ? this.userInfo.user_id : ''
        }
        const res = await getEnabledGoods(obj);
        if(res.code == 0){
          this.enabledGoodsList = res.data;
        }
      },
      // 关闭支付方式选择弹框
      handleClosePaytype(e){
        if(this.$refs.payType){
          const isSelf = this.$refs.guide_type.contains(e.target);
          if(!isSelf){
            this.showPay_dialog = false;
          }
        }
      },
      /**
       * 关闭引导弹窗
       */
      handleCloseGuide(e){
        if(this.$refs.guide_dialog){
          const isSelf = this.$refs.guide_cont.contains(e.target)
          if(!isSelf){
            this.showGuide = false
          }
        }
      },
      /**
       * 选择会员套餐
       */
      choosePackage(val){
        this.getPackageList()
        return (!this.order_id ? (this.packageType = val) : '')
      },
      /**
       * 获取套餐列表
       * 2021-12-22 18:54:53
       * @author SnowRock
       */
      getPackageList() {
        const { order_id } = this
        fetchPackageList({
          order_id
        }).then((res) => {
          const { data } = res
          this.packages = data
          this.$nextTick(() => {
            const info = this.packageList[0]
            this.handleSelectPackage(info)
          })
        })
      },
      /**
       * 处理选择套餐
       * @param info
       */
      handleSelectPackage(info) {
        if (info && this.selectPackage && this.selectPackage.goods_id === info.goods_id)
        return
        if(info){
          this.selectPackage = info;
          this.packageType = info.type;
          this.package_name = info.name;
          this.goods_id = info.goods_id;
          // this.total_amount = info.price*100
          this.getPayInfo();
        }
      },
      /***
       * 获取微信支付信息
       */
      async getPayInfo(){
        const { selectPackage, order_id } = this
        const params = {
          order_id,
          goods_id: selectPackage.goods_id,
          share_token: this.share_token
        }
        const res = await getOrderPayByWxPayH5(params)
        if(res.code == 0){
          this.order_no = res.data.order_no;
          this.pay_url = res.data.pay_url;
          this.handleViewOrderPayWxStatus(this.order_no)
        }
      },
      /**
       * 清除定时器
       */
      handleClearCountDown() {
        if (this.wxPayTimer) {
          clearTimeout(this.wxPayTimer)
          this.wxPayTimer = null
        }
      },
      /**
       * 活动微信订单支付状态
       */
      handleViewOrderPayWxStatus(order_no){
        // this.handleClearCountDown();
        const params = {
          order_no: order_no,
          share_token: this.share_token
        }
        fetchOrderPayStatusByH5(params).then((res)=>{
          // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
          if (res.data.status === -1) {
            // 添加定时器
            let that = this;
            this.wxPayTimer = setTimeout(() => {
              that.handleViewOrderPayWxStatus(order_no, name)
            }, 2000)
            // this.$once('hook:beforeDestroy', ()=>{
            //   clearTimeout(that.wxPayTimer)
            //   that.wxPayTimer  = null;
            // })
          } else if (res.data.status === 0) {
            this.$store.dispatch('mobile/setDialogSuccess', true);
            this.$router.push({
              path: '/home'
            })
            this.sendEventapi();
            this.handleClearCountDown();
          }
        }).catch((error)=>{
          console.log(error)
        })
      },
      /**
       * 事件埋点
       */
      sendEventapi(){
        const obj = {
          package_name: this.package_name,
          package_type: this.packageType == 1 ? '个人' : '企业',
          pay_type: '在线支付（支付宝/微信）',
          op_date: formDateTime(),
          m_name: '',
          mid: '',
          p_origin: '手机等移动设备',
          reg_date: this.selectPackage.create_at,
          user_name: this.userInfo.name,
          user_id: this.userInfo.user_id
        }
        send_eventApi('e_pay', obj)
      },
      /**
       * 立即开通
       */
      openVip(){
        this.showPay_dialog = true;
        // const params = {
        //   total_amount: this.total_amount,
        //   order_no: this.order_id,
        //   desc: this.package_name,
        //   user_id: this.userInfo ? this.userInfo.user_id : ''
        // }
        // 判断是否是在微信内进行支付 微信内打开支付引导弹窗否则正常调起微信支付
        // if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //     this.showGuide = true;
        // }else{
        //   const res = await sendWechatPay(params);
        //   if(res.code == 0){
        //     console.log(res)
        //     window.location.href = res.data.wechatpay_mweb_url;
        //   }
        // }
      },
      /**
       * 立即支付
       */
      handlePay(){
        var ua = navigator.userAgent.toLowerCase(); // 获取判断用的对象
        // 如果在微信外打开用户未登录需要登录
        if (ua.match(/MicroMessenger/i) !== "micromessenger") {
          if(!this.userInfo){
            this.$router.push('/login');
            this.showPay_dialog = false;
          }
        }
        // console.log(this.enabledGoodsList)
        // 判断用户当前所选套餐是否已经购买过 如果已经购买过弹出购买弹窗提示 否则正常进行支付
        const isBuy = this.enabledGoodsList.find((ele)=>{
          return ele.goods_id == this.goods_id
        })
        if(isBuy){
          this.text = isBuy.name;
          this.showDialog = true;
          this.showPay_dialog = false;
        }else{
          // 判断是否是在微信内进行支付 微信内打开支付引导弹窗否则正常调起微信支付 / 支付宝支付
          if (ua.match(/MicroMessenger/i) == "micromessenger") {
            this.showGuide = true;
            this.showPay_dialog = false;
          }else{
            this.showGuide = false;
            this.sendPay();
          }
        }
      },
      /**
       * 发起支付 微信/ 支付宝
       */
      async sendPay(){
        this.showPay_dialog = false;
        if(this.payType == 'weixin'){
          window.location.href = this.pay_url;
        } else {
          const { selectPackage, order_id } = this
          const params = {
            order_id,
            goods_id: selectPackage.goods_id,
            share_token: this.share_token
          }
          const res = await sendAlipay(params);
          // console.log(res)
          if(res.code == 0){
            console.log(res.data.pay_url);
            let form = res.data.pay_url;
            const div = document.createElement('div');
            div.innerHTML = form;
            console.log(div);
            document.body.appendChild(div);
            document.getElementById('alipaysubmit').submit();
          }
        }
      },
      /**
       * 确认购买
       */
      confirm(){
        var ua = navigator.userAgent.toLowerCase();
        // 判断是否是在微信内进行支付 微信内打开支付引导弹窗否则正常调起微信支付
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.showDialog = false;
          this.showGuide = true;
          this.showPay_dialog = false;
        }else{
          this.showDialog = false;
          this.showGuide = false;
          this.sendPay();
        }
      }
    }
}
</script>

<style lang="scss" scoped>
@import '../../styles/mobile_member.scss';
</style>
